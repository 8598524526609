<template>
    <v-card :loading="loading">
        <v-card-title class="text-h5">Estimator</v-card-title>
        <v-card-text>
            <v-form ref="estimator">
                <v-autocomplete
                    @change="deleteSearch"
                    label="Estimator"
                    v-model="estimatorId"
                    :items="users"
                    item-text="name"
                    chips
                    item-value="id"
                    :rules="[rules.required]"
                >
                </v-autocomplete>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-btn text color="secondary" @click="closeDialog">
                Close
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn @click="update" text color="error" :loading="loading">
                Save
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
import { loadImage } from '@/helpers/imageHandler'
import API from '@/services/api'

export default {
    name: 'ChangeEstimator',
    props: {
        quoteId: {
            type: String,
            required: true,
            default: () => {
                return ''
            },
        },
        quote: Object,
    },
    data: () => {
        return {
            estimatorId: null,
            loading: false,
            errMessage: '',
            companyId: JSON.parse(localStorage.getItem('company')),
            folderUsersPictures: 'users_pictures',
            users: [],
            rules: {
                required: v => !!v || 'Required',
            },
        }
    },
    async mounted() {
        try {
            this.loading = true
            const {
                data: { users },
            } = await API.getLiteUsers()
            this.users = users
            if (this.quote.estimatorId) {
                this.estimatorId = this.users.find(
                    u => u.id == this.quote.estimatorId
                )
            }
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        closeDialog() {
            this.$emit('closeChangeEstimator')
        },
        deleteSearch() {
            this.search = ''
        },
        async update() {
            try {
                this.loading = true
                const user = this.users.find(u => u.id === this.estimatorId)
                if (user) {
                    if (user.picture) {
                        user.profilePicURL = await loadImage(
                            `${this.companyId}/${this.folderUsersPictures}`,
                            user.picture
                        )
                    }
                    const response = await API.changeEstimator({
                        id: this.quoteId,
                        estimatorId: this.estimatorId,
                    })
                    response.user = user
                    this.$emit('replaceEstimator', response)
                    this.$emit('closeChangeEstimator')
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
    },
}
</script>
